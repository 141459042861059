import React from "react";
import './AllTheCSS/CustomButton.css'

interface props {
    children: React.ReactNode
    onClick: () => void
    type?: string
}

const CustomButton = ({onClick, type, children}: props) => {

    return (
        <div className={type === 'request'? "CustomButton-Request": "CustomButton"} onClick={onClick} >
            {children}
        </div>
    )
}
export default CustomButton