import './AllTheCSS/Dining.css';
import DiningLocations from './Pictures/DiningLocations.webp'
import EventHeader from "./EventHeader";
import DiningHeader from './Pictures/DiningHeader.webp'
import React from "react";
import Reveal from "./Reveal";


const Dining = () => {

    return (

           <Reveal>
               <div className={"Dining-Container"}>
                   <div className={"Dining-DescriptionContainer"}>
                       <div className={"Dining-RDF"}>
                           <h1>Ranger Dining Facility</h1>
                           <p>Our dining hall will serve meals for a large number of guests, ensuring everyone is well-fed and comfortable.
                               This is the perfect place to enjoy a hearty meal and connect with fellow attendees.</p>
                       </div>
                       <div className={"Dining-FoodTrucks"}>
                           <h1>Food Trucks</h1>
                           <p>Located north of the RDF, our food trucks offer a diverse selection of tasty treats and refreshments.
                               Whether you're looking for a quick snack or a hearty meal, the food trucks have something for everyone.</p>
                       </div>
                   </div>
                   <img className={"Dining-Map"} src={DiningLocations} alt={'Dining Locations'}/>
               </div>
           </Reveal>

    );
}
export default Dining